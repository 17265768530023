
#mode-box-mode, #mode-box #set-id {
  width: 15em; // Match width to that of the
}

article.post-preview {
  box-sizing: border-box;
  height: auto;
  width: 180px;
  margin: 0 10px 10px 0;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  position: relative;
  vertical-align: text-top;

  a {
    display: inline-block;
  }

  .desc {
    background-color: themed("color-section-lighten-5");
    font-size: 80%;
    margin-bottom: 0;
  }
  &.filter-matches .desc {
    background-color: var(--palette-background-red);
  }

  .post-score>span {
    font-size: 0.8rem;
    margin-left: 0.5em;
  }

  &.post-rating-explicit .post-score-rating {
    color: palette("text-red");
  }

  &.post-rating-safe .post-score-rating {
    color: palette("text-green");
  }

  &.post-rating-questionable .post-score-rating {
    color: palette("text-yellow");
  }

  img {
    box-sizing: border-box;
    margin: auto;
    max-height: 12.5rem; // Roughly 200px with 16pt font size
    max-width: 12.5rem;
    border-radius: $border-radius-half $border-radius-half 0 0;
  }

  &[data-tags~=animated] > a:before {
    @include  preview-type-badge('ANIM');
  }

  &[data-file-ext=webm] > a:before {
    @include preview-type-badge('WEBM');
  }
}

#edit-dialog textarea {
  margin-bottom: 0.25em;
}

.post-preview {
  background: #00000040;
  display: block;
  position: relative;
  width: min-content;
  height: min-content;

  div.ribbons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    z-index: 10;

    > div.ribbon {
      position: absolute;
      width: 1rem;
      height: 1rem;
      overflow: hidden;
      display: block;

      & > span {
        display: block;
        position: absolute;
        top: -0.6rem;
        height: 1.25rem;
        width: 2rem;
        background: white;
        transform-origin: bottom;
        cursor: pointer;

        box-shadow: 0 0 10px -4px black;
      }

      &.right {
        right: 0;
        border-top-right-radius: 6px;

        span {
          transform: rotate(45deg);
          right: -0.6rem;
        }
      }

      &.left {
        left: 0;
        border-top-left-radius: 6px;

        span {
          transform: rotate(-45deg);
          left: -0.6rem;
        }
      }

      &.has-children span { background: $preview-has-children-color; }
      &.has-parent span { background: $preview-has-parent-color; }
      &.has-children.has-parent span { background: linear-gradient(90deg,$preview-has-parent-color 50%, $preview-has-children-color 50%); }
      &.is-flagged span { background: $preview-flagged-color; }
      &.is-pending span { background: $preview-pending-color; }
      &.is-flagged.is-pending span { background: linear-gradient(90deg,$preview-pending-color 50%, $preview-flagged-color 50%); }
    }
  }

  div#vote-buttons {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: flex-end;
    bottom: 1rem;
    left: 0;
    right: 0;
    height: 0;
    z-index: 10;

    button.vote-button {
      font-size: 0.9rem;
      padding: 0.125rem 0.75rem;
      border-radius: 0;

      & span {
        pointer-events: none;
      }

      &.vote {
        display: none;
        padding: 0.125rem 1rem;

        & span::before {
          @include FontAwesomeFamily;
          font-weight: 500;
          position: relative;
        }
        &[data-action=up] {
          border-right: 1px solid #666;
          border-top-left-radius: 0.75rem;
          margin-right: -0.5px;
          padding-right: 0.7rem;
          box-shadow: -1px -3px 5px #00000060;

          & span::before {
            content: unicode("f164");
            bottom: -1px;
          }
        }
        &[data-action=down] {
          border-left: 1px solid #666;
          border-top-right-radius: 0.75rem;
          margin-left: -0.5px;
          padding-left: 0.7rem;
          box-shadow: 1px -3px 5px #00000060;

          & span::before {
            content: unicode("f165");
            top: 2px;
            left: 2px;
          }
        }
      }

      &.fav {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0.125rem 0.375rem;
        border-top-right-radius: 0.75rem;
        box-shadow: 1px -3px 5px #00000060;

        & span::before {
          content: unicode("f005");
          @include FontAwesomeFamily;
          font-weight: 500;
          position: relative;
          top: 1px;
          right: 1px;
        }
        & span.is-favorited { color: goldenrod; }
      }
    }
  }

  &:hover div#vote-buttons {
    button.vote-button.vote, button.vote-button.fav {
      display: block;
    }
  }

  &[data-is-favorited=true] {
    button.vote-button.fav {
      display: block;
    }

    &:not(:hover) button.vote-button.fav {
      background: #00000090;
      &::before { font-weight: 600; }
    }
  }
}

.post-preview.current-post {
  background-color: $post-preview-highlight-background;
}

#has-parent-relationship-preview, #has-children-relationship-preview {
  flex-direction: row;
  flex-wrap: wrap;

  article.post-preview {
    border: none;
    margin: 0;
    padding: 5px 5px 10px;
  }
}

div#c-posts {
  .source-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
  }
  .source-link:hover {
    a {
      background: themed("color-background");
    }
    overflow: visible;
    height: auto;
    z-index: 2;
    position: relative;
    max-width: none;
  }

  .post-protection {
    color: var(--color-text-muted);
    margin-bottom: 0.25em;
    font-size: 85%;
  }

  .fav-buttons {
    font-size: 14pt;

    button.ui-button {
      padding: 0.25em 0.75em;
    }
  }

  .fav-buttons-true #add-fav-button {
    display: none;
  }

  .fav-buttons-false #remove-fav-button {
    display: none;
  }

  .fav-buttons,
  #image-download-link {
    .button > i {
      display: none;
    }
  }

  @media only screen and (max-width: 500px) {
    #image-extra-controls {
      justify-content: center;
    }

    .fav-buttons,
    #image-download-link {
      .button > i {
        display: inline-block;
      }
      .button > span {
        display: none;
      }
    }
  }

  div.parent-children {
    display: flex;
    margin: 0 (-$padding-025);
    margin-bottom: $padding-025;
    div.notice {
      margin: 0 $padding-025;
    }
    div.notice-parent {
      flex-grow: 1;
    }

    &.enhanced {
      display: block;

      & #has-children-relationship-preview,
      & #has-parent-relationship-preview {
        display: grid;
        justify-items: center;
        max-height: 50vh;
        ovferflow-y: auto;
        @include scrollbars;
      }
    }
  }

  div.notice {
    border-radius: $border-radius-half;
    padding: 0.5em;
    margin-bottom: 0.5em;
    overflow: hidden;

    ul {
      margin-left: 1em;
    }

    p {
      margin: 0;
    }

    .resolved {
      margin-left: 0.5em;
      font-weight: bold;
    }

    &.notice-parent, &.notice-child, &.notice-pending, &.notice-resized {
      background-color: themed("color-section");
      border: 1px solid themed("color-foreground");
    }

    &.notice-no-shortcuts, &.notice-flagged, &.notice-deleted, &.notice-appealed {
      background-color: themed("color-danger-darken-10");
      border: 1px solid themed("color-foreground");
    }
  }

  div.nav-notice {
    display: flex;
    flex-flow: column;
    gap: 0.125rem;

    margin-bottom: $padding-050;
    background-color: themed('color-section');
    position: relative;

    p {
      margin: 0;
      text-align: center;
    }
  }

  aside#sidebar #tag-list h2 {
    font-size: $h4_size;
  }

  div#a-index {
    menu#post-sections {
      margin-bottom: 0.5em;
      font-size: $h3-size;

      li {
        padding: 0 1em 0.5em 0;

        &.active {
          font-weight: bold;
        }
      }
    }
  }

  div#a-show {
    .active, .pool-selected-true {
      font-weight: bold;
    }

    menu#post-sections {
      margin: 0;
      font-size: $h3-size;

      li {
        padding: 0 1em 0 0;
      }

      div.share a {
        margin: 0;
      }
    }

    div.notices {
      margin: 1em 0;
    }

    div#avoid-posting-notice {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding: $padding-025 $padding-050;

      background-color: themed("color-section");
      border: 1px solid themed("color-foreground");

      ul {
        list-style: disc;
      }

      li {
        .artist, .separator, .details {
          display: table-cell;
        }

        .separator {
          color: var(--color-text-muted);
          padding: 0 0.3rem;
        }
      }
    }

    #pending-approval-notice {
      .notice-pending-toolbar { display: none; }
      &.enabled {
        .notice-pending-status { display: none; }
        .notice-pending-toolbar { display: block; }
      }
    }

    div.quick-mod {

      .quick-mod-group {
        display: flex;
        color: var(--color-text-muted);
        align-items: center;
        flex-wrap: wrap;
        margin: 0.25em 0;

        a { text-wrap: nowrap; }
      }

      .btn-important {
        font-weight: 700;
      }
    }

    textarea {
      margin-bottom: 0.25em;
    }

    #edit {
      .edit-submit {
        position: sticky;
        bottom: 0;

        background: themed("color-section");
        width: min-content;
        padding: 1rem 1rem 1rem 0.5rem;
        margin: 0;
      }
      textarea {
        width: 100%;
      }

      #post_rating_e:checked + label {
        background-color: themed("color-rating-explicit");
      }

      #post_rating_q:checked + label {
        background-color: themed("color-rating-questionable");
      }

      #post_rating_s:checked + label {
        background-color: themed("color-rating-safe");
      }
    }

    .nav-block {
      background: themed("color-section");

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5em;

        .nav-link {
          flex-shrink: 2;
          padding: 0.5em
        }
        a.nav-link:hover {
          background: themed("color-section-lighten-5");
        }
        span.nav-name {
          flex-grow: 2;
          text-align: center;

          // Prevent the contents from overflowing
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 0;
        }
      }
    }

    .set-nav .set-name {
      a#remove-from-set-button {
        margin-left: 1rem;
        cursor: pointer;
      }
    }

    span.close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    div.comments-for-post {
      width:100%
    }
  }

  div#quick-edit-div {
    textarea {
      width: 70%;
      height: 4em;
      display: block;
    }
  }
}

div#avoid-posting-notice {
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: $padding-025 $padding-050;

  background-color: themed("color-section");
  border: 1px solid themed("color-foreground");

  ul {
    list-style: disc;
  }

  li {
    .artist, .separator, .details {
      display: table-cell;
    }

    .separator {
      color: var(--color-text-muted);
      padding: 0 0.3rem;
    }
  }
}

div#c-posts-versions, div#c-artists-versions {
  div#a-index {
    a {
      word-wrap: break-word;
      word-break: keep-all;
    }
  }
}

div#c-popular {
  a.desc {
    font-weight: bold;
    margin: 0 0.5em;
  }

  #popular-nav-links {
    text-align: center;
  }

  .period {
    margin: 0 4.75em;
  }
}

div#unapprove-dialog {
  p {
    margin-bottom: 1em;
  }
}

textarea[data-autocomplete="tag-edit"] {
  font-family: monospace;
}

#image {
  &.fit-window {
    max-width: 100%;
  }
  &.fit-window-vertical {
    max-width: 100%;
    max-height: 95vh;
  }
}

section#image-extra-controls {
  display: flex;
  align-items: center;

  div {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }

  flex-wrap: wrap;
  margin: 0.25rem 0;

  & > div {
    margin: 0.25rem 0.5rem 0.25rem 0;

    &:last-child {
      margin-right: 0;
    }

    & > a, & > button, & > select {
      outline: 0;
      @include nonselectable;
    }
  }

  div#image-vote-buttons {
    & > a > span {
      font-size: 1rem;
    }

    & > span {
      padding: 0 0.5rem;
    }
  }

  div#image-full-screen-button > a {
    width: 7rem;
    text-align: center;
    position: relative;
  }

  div#image-add-buttons > a,
  div#image-note-buttons > a,
  div#image-full-screen-button > a {
    margin-right: 0.2srem;
    &:last-child { margin-right: 0; }

    &:nth-child(1) {
      margin-right: 0.1rem;
    }

    &:nth-child(2) {
      left: 0.1rem;
    }
  }

  div#image-download-button > a {
    text-align: center;
    position: relative;
  }
}

section#tag-list {
  word-break: break-word;
}

#tag-list > ul {
  li {
    display: flex;
    line-height: 1rem;
    margin-bottom: 1px;

    position: relative;

    a.search-inc-tag,
    a.search-exl-tag {
      padding-right: 0.125rem;
    }

    a.follow-button-minor {

      &::after {
        content: unicode("f004");
        @include FontAwesomeFamily;
        font-weight: 500;
      }

      &[data-followed=true]::after {
        font-weight: 700;
      }
    }

    span.tag-wrap {
      margin-right: 0.25rem;
    }

    div.tag-actions {
      min-width: fit-content;
      margin-left: auto;
      line-height: 1rem;

      position: absolute;
      right: -0.85rem;

      span {
        margin: 0 0.125rem;
        min-width: fit-content;

        &.tag-action-blacklist {
          display: none;
        }

        &.tag-action-follow a[data-followed=false] {
          visibility: hidden;
        }
      }
    }

    span.post-count {
      margin-left: 0.5rem;
      white-space: nowrap;
      font-size: 90%;
    }

    &:hover {

      a.search-tag {
        white-space: normal;
        max-width: unset;
      }

      div.tag-actions {

        span {

          &.tag-action-blacklist {
            display: inline-block;
          }

          &.tag-action-follow a {
            visibility: visible;
          }
        }
      }
    }
  }
}

.tag-list-header {
  cursor: pointer;
  &.hidden-category::before {
    content: "► ";
  }
}

.image-vote-buttons {
  .post-score {
    padding: 0 $padding-050;
  }
}

div#image-and-nav {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);
  width: fit-content;

  div.nav-notice {
    margin-bottom: 0;
  }

  [data-th-nav=bottom] & {
    #nav-links-top {
      display: none;
    }
  }
  [data-th-nav=top] & {
    #nav-links-bottom {
      display: none;
    }
  }
  [data-th-nav=none] & {
    #nav-links-top, #nav-links-bottom {
      display: none;
    }
  }
}

body[data-user-can-approve-posts="true"] .notice {
  &.notice-flagged, &.notice-deleted, &.notice-pending, &.notice-appealed {
    .button {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding: $padding-025 $padding-050;
    }
  }
}

/* Flag page */
.flag-dialog-preview {
  @media only screen and (min-width: 550px) {
    display: flex;
  }

  // Overwrite some DText styles to make the header look better
  blockquote {
    border: 0;
    text-align: center;
    h3 { padding: 0; }
  }
  p { margin-bottom: 0.25em; }
}

.flag-dialog-body {

  // Option label
  label {
    font-weight: normal;
    font-size: 1rem;
    cursor: pointer;
  }

  // Option explanation
  div.styled-dtext {
    margin: 0.125rem 1.25rem;
    filter: brightness(85%);
  }

  // Align label with the explanation
  input[type="radio"] {
    width: 1rem;
  }

  // Post ID input
  form.simple_form div.input {
    margin: -0.5rem 0 0 1.25rem;
    label {
      font-weight: normal;
      margin-right: 1rem;
      cursor: default;
    }
    &.post_flag_parent_id { display: flex }
    span.error { margin-left: 1rem; }
  }

  hr { margin: 0.75rem 1.25rem; }
  h3 {
    margin: 0.5rem 1.25rem;
    font-weight: normal;
  }
  input[type="submit"] { margin: 0.75rem 1rem 0.5rem; }
}

section#post-information .post-uploader-artist {
  font-weight: bold;
  color: #e45f5f;
}
