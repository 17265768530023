.forum-post-votes {
  .vote-score-up {
    color: palette("text-green");
  }

  .vote-score-meh {
    color: palette("text-yellow");
  }

  .vote-score-down {
    color: palette("text-red");
  }

  .forum-post-vote-rating {
    margin-left: 0.5rem;
  }

  &.forum-post-votes-overview {
    margin-top: 0.3rem;
  }
}

div.list-of-forum-posts {
  article {
    margin: 1em 0em;

    a.voted {
      font-weight: bold;
    }

    span.desc {
      color: grey;
    }

    &:target {
      background-color: $forum-post-highlight-background;
    }

    &[data-is-hidden="true"] {
      background-color: $background-article-hidden;
    }

    div.content-menu {
      .edit_forum_post, .edit_forum_topic {
        display: none;
      }

      menu {
        margin-top: 0.5em;

        li {
          margin-right: 1em;

          &.spam, &.ip-address {
            margin-right: 0;
          }

          &.spam a{
            color: goldenrod;
          }
        }
      }

      .forum-post-votes-list {
        margin-top: 0.3em;
        margin-left: 0.2em;
        margin-bottom: 0.2rem;
      }
    }
  }
}

.forums-posts-votes-list {
  li {
    display: inline;
    margin-right: 1em;
  }
}

div#c-forums-topics {
  span.locked-topic {
    color: themed("color-link-last-page");
  }

  tr.forum-topic-row {
    &[data-is-hidden="true"] {
      background-color: $background-article-hidden;
    }

    td:last-child {
      white-space: nowrap;
    }

    .forum-post-link {
      word-break: break-word;
    }
  }
}

#c-forums-posts #a-index {
  tr[data-is-hidden="true"], tr[data-topic-is-hidden="true"] {
    background-color: $background-article-hidden;
  }
}

#c-forums #a-index, #c-forums-topics #a-index {
  span.new {
    font-size: 80%;
    color: $forum-topic-new-color;
    margin-right: 0.5em;
    font-weight: normal;
  }
}
